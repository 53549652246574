import React from 'react';
import {  TabBar } from 'antd-mobile'
import {
 useNavigate ,
  useLocation,
} from 'react-router-dom'
import {
  AppOutline,
  MessageOutline,
  UnorderedListOutline,
  UserOutline,
} from 'antd-mobile-icons'

import './index.scss'

const Fbody = () => {

  const navigate  = useNavigate ()
  const location = useLocation()
  const { pathname } = location

  const setRouteActive = (value) => {
    navigate  (value)
  }

  const tabs = [
    {
      key: '/home',
      title: '首页',
      icon: <AppOutline />,
    },
    {
      key: '/todo',
      title: '待办',
      icon: <UnorderedListOutline />,
    },
    {
      key: '/message',
      title: '消息',
      icon: <MessageOutline />,
    },
    {
      key: '/me',
      title: '我的',
      icon: <UserOutline />,
    },
  ]



  return (
    <>
      <div className='fbody'>
      <TabBar activeKey={pathname} onChange={value => setRouteActive(value)} >
      {tabs.map(item => (
        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
      ))}
    </TabBar>
      </div>
      
    </>



  );



};
export default Fbody



