import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { RouterProvider } from 'react-router-dom';
import router from './router';

import 'normalize.css'

// const config1=JSON.stringify(config);

export const Conf = createContext({});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render( <RouterProvider router={router} /> );

