import React from 'react';
import './index.scss';
import { useSearchParams } from 'react-router-dom';
import Fbody from '@/pages/Fbody';

const Am = () => {
const [a,seta]= useSearchParams()


   console.log("lianjie ："+a)

    
   

    return <>
   <div onClick={()=>{  seta("a=1&b=2")  }}>77777777{a.get('a')}</div>

<Fbody></Fbody>

   
        

    </>

}
export default Am