import React from 'react';
import {Result } from 'antd';
const NotFound = () => (
  <Result
    status="404"
    title="404"
    subTitle="抱歉，您访问的资源不存在!"
    
    // extra={<Button type="primary">Back Home</Button>}
  />
);
export default NotFound;