
import Am from '@/pages/Am'

import { createBrowserRouter } from 'react-router-dom'

import NotFound from '@/pages/NotFound'





const router = createBrowserRouter([
   
    {path: '/',element: <Am />},


    {path: '*', element: <NotFound />}
])
export default router